import {EXP_RETAIL_UNIFIED_FUNNEL} from '@/experiments';
import {useExperiment} from '@/hooks/useExperiment';
import {useSiteData} from '@/hooks/useSiteData';

export const useRetailUnifiedFunnel = () => {
  const variant = useExperiment(EXP_RETAIL_UNIFIED_FUNNEL);
  const {site} = useSiteData();

  return {
    isRetailUnifiedFunnelExperiment:
      site.locale === 'en' && variant === 'treatment',
  };
};
